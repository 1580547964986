import { useState } from "react";
import "./App.less";
import {
  Row,
  Col,
  Form,
  Input,
  Button,
  Spin,
  Space,
  Radio,
  Upload,
  Segmented,
  Image,
  message
} from "antd";
import { UploadOutlined } from "@ant-design/icons";

import { createPic, createPicByImage2Image } from "./api";
// import { useForm } from "antd/es/form/Form";

type scaleType = "无" | "4:3" | "3:4" | "16:9" | "9:16";

function App() {
  const [imgList, setImageList] = useState<
    {
      file_name: string;
      uri: string;
      seed: number;
    }[]
  >([]);

  const [loading, setLoading] = useState(false);
  const [type, setType] = useState<string>('文生图');

  const [form] = Form.useForm();

  const scaletype = {
    '无': { width: undefined, height: undefined },
    "4:3": { width: 768, height: 1024 },
    "3:4": { width: 1024, height: 768 },
    "16:9": { width: 1280, height: 720 },
    "9:16": { width: 720, height: 1280 },
  };

  const onFinish = async (values: any) => {
    console.log("Received values of form: ", values);
    setLoading(true);

    if (type === '图生图' && values?.image?.file?.response?.name) {
      try {
        const result = await createPicByImage2Image({
          ...values,
          image: values?.image?.file?.response?.name,
          scale: undefined,
        });
        if (result?.data.file_name) {
          const newList = [result?.data, ...imgList];
          setImageList(newList);
          setLoading(false);
        } else {
          message.error(result?.data.message)
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
      }
      return
    }

    const scaleValue = scaletype[values?.scale as scaleType];
    try {
      const result = await createPic({
        ...values,
        ...scaleValue,
        scale: undefined,
      });
      if (result?.data.file_name) {
        const newList = [result?.data, ...imgList];
        setImageList(newList);
        setLoading(false);
      } else {
        message.error(result?.data.message)
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <Spin spinning={loading}>
      <div className="AppLess">
        <div className="appMainBox">
          <div className="appInnerBox">
            <Row gutter={[16, 16]} style={{ height: "100%" }}>
              <Col span={9}>
                <div className="leftBoxTop">
                  <div style={{ height: "24px" }}></div>
                  <h2>AI 内容生成编辑区</h2>
                  <Segmented value={type} options={['文生图', '图生图']} block onChange={(w) => { 
                    setType(w)
                   }} />
                  <div style={{ height: "24px" }}></div>
                  <Form
                    form={form}
                    size="middle"
                    layout="vertical"
                    name="validate_other"
                    onFinish={onFinish}
                    initialValues={{
                      scale: "无",
                    }}
                  >

                    {type !== '图生图' && <Form.Item
                      name="scale"
                      label="比例"
                      style={{ width: "100%" }}
                    >
                      <Radio.Group>
                        <Radio.Button value="无">无</Radio.Button>
                        <Radio.Button value="4:3">4:3</Radio.Button>
                        <Radio.Button value="3:4">3:4</Radio.Button>
                        <Radio.Button value="16:9">16:9</Radio.Button>
                        <Radio.Button value="9:16">9:16</Radio.Button>
                      </Radio.Group>
                    </Form.Item>}

                    {/* <div style={{ height: '60px' }}></div> */}
                    {/* <Form.Item
                    name="speed"
                    label="speed"
                  >
                    <InputNumber placeholder='根据之前的speed生成原图片'  style={{width: '100%'}} />
                  </Form.Item> */}

                    <Form.Item
                      name="text"
                      label="文本"
                      rules={[
                        {
                          required: true,
                          message: "当前字段不可为空!",
                        },
                      ]}
                    >
                      <Input.TextArea rows={4} maxLength={100} />
                    </Form.Item>
                    {type === '图生图' && <Form.Item name="image">
                      <Upload
                        // action="http://172.19.72.212/api/upload/public"
                        action="http://dududu.net.dadashe.cn/api/upload/public"
                        accept=".jpg,.jepg,.png"
                        listType="picture-card"
                        maxCount={1}
                      >
                        <button
                          style={{ border: 0, background: "none" }}
                          type="button"
                        >
                          <UploadOutlined style={{ color: '#fff' }} />
                          <div style={{ color: '#fff' }}>导入参考图</div>
                        </button>
                      </Upload>
                    </Form.Item>}

                    {/* <div style={{ height: '260px' }}></div> */}

                    <Form.Item className="submitBtnWrapper">
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="submitBtn"
                        block
                      >
                        提交
                      </Button>
                    </Form.Item>
                  </Form>
                  {/* <Button type="primary" htmlType="submit" style={{ float: 'right' }}>
                        提交
                  </Button> */}
                </div>
              </Col>
              <Col span={15} style={{ height: "100%" }}>
                <div className="rightBoxTop">
                    <div className="rightInnerBoxTop">
                    <Image.PreviewGroup>
                      <Space direction="vertical" size="middle">
                      {imgList.map((x) => {
                      return (<Image
                        key={x.seed}
                        width={'70%'}
                        src={x?.uri}
                      />)})}
                    </Space>
                    </Image.PreviewGroup>
                    </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </Spin>
  );
}

export default App;
