import axios from 'axios'

const axiosInstance1 = axios.create({
  baseURL:'https://dududu.net.dadashe.cn/api',
    // baseURL:'http://172.19.72.212/api',
    // timeout:15000
})


// 根据文字创建图片
export const createPic = (params:{
  "text": string,
  "seed": number,
  "width": number,
  "height": number
}) => {
 return axiosInstance1.post('/photo',params)
}

// 根据图片及描述生成图
export const createPicByImage2Image = (params:{
  "text": string,
  'image': string,
  "seed": number,
  "width"?: number,
  "height"?: number,
  "negative?": string,
}) => {
 return axiosInstance1.post('/image2image',params)
}
